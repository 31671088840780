.work__modal-backdrop {
	position: fixed;
	z-index: 15;

	width: 100%;
	height: 100vh;
	// overflow: hidden;
	////////
	background: rgba(255, 255, 255, 0.31);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8.9px);
	-webkit-backdrop-filter: blur(8.9px);
	border: 1px solid rgba(255, 255, 255, 0.48);
}

.work__modal-overlay {
	position: fixed;
	z-index: 16;

	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.modal-close-button {
		position: fixed;
		z-index: 20;
		top: 5%;
		right: 5%;

		width: 2rem;
		height: 3rem;

		color: gray;

		&:hover {
			transform: scale(1.2);
		}
	}

	.image-container {
		//this will resize the modal
		width: auto;
		height: auto;

		// width: 98.2%;
		// height: 94%;
		// border: 2px solid red;
		// overflow: hidden;

		display: flex;
		justify-content: space-around;
		align-items: center;

		// transform: scale(1.1);

		.imgMain {
			display: none;
		}

		.img-active {
			width: 80%;

			height: 100%;

			border-radius: 30px;

			box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			-webkit-box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			-moz-box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			// animation: image-show 0.5s linear;
			user-select: none;

			@media screen and (max-width: 600px) {
				width: 320px;
				height: 240px;
			}
		}

		.img-active2 {
			width: auto;
			height: auto;
			border-radius: 30px;

			box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			-webkit-box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			-moz-box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			// animation: image-show 0.5s linear;
			user-select: none;

			@media screen and (max-width: 600px) {
				width: 320px;
				height: 300px;
			}
		}

		.img-active3 {
			// width: 1038px;
			// height: 700px;

			width: 70%;
			height: 100%;

			border-radius: 30px;
			box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			-webkit-box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			-moz-box-shadow: 1px 10px 51px -2px rgba(0, 0, 0, 0.54);
			// animation: image-show 0.5s linear;
			user-select: none;

			@media screen and (max-width: 600px) {
				//This will resize the modal in mobile

				width: 80%;
				height: 56%;

				// width: 320px;
				// height: 300px;
			}
		}

		@keyframes image-show {
			0% {
				transform: scale(0.9);
				opacity: 0.7;
			}

			100% {
				opacity: 1;
				transform: scale(1);
			}
		}

		svg {
			font-size: 2rem;
			color: rgba(0, 0, 0, 0.4);
			transition: all linear 0.1s;

			&:hover {
				transform: scale(1.2);
				box-shadow: 0 0.5rem 1rem 1rem rgba(0, 0, 0, 0.1);
				border-radius: 100px;
			}

			@media screen and (max-width: 600px) {
				width: 20px;
				align-self: center;
			}
		}

		@media screen and (max-width: 600px) {
			// flex-direction: column;

			width: 100%;
			height: 50%;
		}
	}
}
