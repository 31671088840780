@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

#about {
  background-image: url(../../assets/unsplash-about1.jpg);
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
}

.about-container {
  .app__about-me {
    font-style: normal;
    line-height: 2rem;
    margin: 10px;
    display: flex;
    justify-content: center;

    p {
      font-size: large;
      font-weight: 500;
      text-justify: auto;
      text-align: center;
      width: 100%;
      margin: 5px;
      display: block;
    }
  }
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
  transition: smooth;
}

.app__profiles {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.app__profile-item {
  width: 240px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 1rem;

  background-color: #fff;
  border-radius: 15px;

  .bold-text {
    align-self: center;
    margin-top: 10px;
  }

  img {
    width: 100%;
    height: 10rem;
    border-radius: 15px 15px 0 0;
    object-fit: cover;
  }

  .app__profile-item-p-text {
    font-size: 0.8rem;
    font-weight: 500;
    text-indent: 15px;
    text-align: justify;
    padding: 12px;
    border-radius: 10px;
    height: 10rem;
    overflow: auto;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

.accordian__container-parent {
  width: 95%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.accordian__container-item {
  // padding: 1rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .accordian__heading {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2rem;

    // background-color: rgb(255, 255, 255, 0.3);
    background: transparent;
    backdrop-filter: blur(1px);

    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }

    h2 {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      letter-spacing: 2px;
      text-decoration: underline orange;
      text-underline-offset: 8px;
    }
    span {
      font-size: 2rem;
      margin-right: 2rem;
    }
  }

  .accordian__content {
    // background-color: rgb(255, 255, 255, 0.3);
    backdrop-filter: blur(1px);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    font-size: 1.2rem;
    margin-left: 4rem;

    .accordian__content-text {
      height: 0;
      opacity: 0;
      padding: 1 1rem;
      overflow: hidden;
      transition: all 1s ease-in-out;
    }
    .accordian__content-active {
      height: auto;
      opacity: 1;
    }
  }
}
